import React from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import Breadcrumb from "../components/Global/Breadcrumb"
import { GatsbyImage } from "gatsby-plugin-image";

import "../css/BiographyTemplate.css";





function BiographyTemplate(props) {
    let data = props.data.wpgraphql;

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.page.breadcrumbAcf.breadcrumbName,
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}${props.pageContext.currentPage}`
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://plusmedical.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://plusmedical.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts = { data.posts }
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                examinations={props.pageContext.examinationsCategories}
                preHeader={data.template.contactInformationTemplate}
            >
                <div>
                    {data.page.breadcrumbAcf.breadcrumbName && (
                        <section>
                            <Breadcrumb list={getBreadcrumb()} image={data.page.breadcrumbAcf.image} h1={data.page.breadcrumbAcf.breadcrumbName} />
                        </section>
                    )}

                    <section>
                        <div>
                            <section className="container m-auto px-4 md:px-0" style={{ maxWidth: "1000px" }}>
                                <div className="flex flex-wrap pb-4 md:pb-0 pt-8 md:pt-20">
                                    <div className="w-full md:w-1/3">
                                        <GatsbyImage
                                            image={data.page.biographyCpt.featuredImage.imageFile.childImageSharp.gatsbyImageData}
                                            alt={data.page.biographyCpt.featuredImage.altText}
                                            className="h-full w-full m-auto" />
                                    </div>
                                    <div className="w-full md:w-2/3 pl-0 md:pl-12 pt-8 md:pt-0">
                                        <div className="bio-editor" dangerouslySetInnerHTML={{ __html: data.page.biographyCpt.information }} />
                                    </div>
                                </div>
                            </section>

                            <section className="container m-auto pb-16 px-4 md:px-0 pt-0 md:pt-8" style={{ maxWidth: "1000px" }}>
                                <div className="bio-editor2" dangerouslySetInnerHTML={{ __html: data.page.biographyCpt.biography }} />
                            </section>

                            {/* <section style={{ backgroundColor: "#f6faff" }}>
                                <div className="py-12 md:py-20 flex flex-wrap container m-auto px-4 md:px-0" style={{ maxWidth: "1000px" }}>
                                    <div className="w-full md:w-1/2">
                                        <h2 className="pb-8">{data.page.biographyCpt.workExperienceGroup.title}</h2>
                                        <div>
                                            {data.page.biographyCpt.workExperienceGroup.experience && data.page.biographyCpt.workExperienceGroup.experience.map((stat, i) => (
                                                <div key={`stat-${i}`} class="relative pt-2">
                                                    <div class="flex mb-2 items-center justify-between">
                                                        <div>
                                                            <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full" style={{ color: "#031b4e", fontSize: "16px" }}>
                                                                {stat.title}
                                                            </span>
                                                        </div>
                                                        <div class="text-right">
                                                            <span class="font-semibold inline-block" style={{ color: "#031b4e", fontSize: "16px" }}>
                                                                {stat.percentage}%
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="overflow-hidden h-2 mb-4 text-xs flex rounded" style={{ backgroundColor: "#f5f9fd" }}>
                                                        <div style={{ width: `${stat.percentage}%`, backgroundColor: "#244989" }} class="stat-p shadow-none flex flex-col text-center whitespace-nowrap justify-center"></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="w-full md:w-1/2 pt-8 md:pt-16 pl-0 md:pl-8">
                                        {data.page.biographyCpt.workExperienceGroup.skills && data.page.biographyCpt.workExperienceGroup.skills.map((skill, i) => (
                                            <div className="flex mt-8" key={`skill-${i}`}>
                                                <div>
                                                    <GatsbyImage
                                                        image={skill.icon.imageFile.childImageSharp.gatsbyImageData}
                                                        alt={skill.icon.altText} />
                                                </div>
                                                <div className="pl-2">
                                                    <div style={{fontSize: "19px", color: "#031b4e"}}>
                                                        {skill.title}
                                                    </div>
                                                    <p className="pt-2">
                                                        {skill.description}
                                                    </p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                            <section className="container m-auto py-12 md:py-20 px-4 md:px-0" style={{ maxWidth: "1000px" }}>
                                <div className="flex flex-wrap">
                                    <div className="w-full md:w-1/3">
                                        <div className="bio-editor3" dangerouslySetInnerHTML={{__html: data.page.biographyCpt.doctorsAchievements}} />
                                    </div>
                                    <div className="w-full md:w-2/3">
                                        <div className="flex flex-wrap justify-center">
                                            { data.page.biographyCpt.achievementsGallery && data.page.biographyCpt.achievementsGallery.map((ach,i) => (
                                                <div className="pt-4 md:pt-0 w-full md:w-1/3 flex justify-center" key={`achievements-${i}`}>
                                                    <GatsbyImage
                                                        image={ach.image.imageFile.childImageSharp.gatsbyImageData}
                                                        alt={ach.image.altText} />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </section> */}
                        </div>
                    </section>
                </div>
            </Layout>
        </div>
    );
}

export default BiographyTemplate

export const pageQuery = graphql`query GET_BIOGRAPHY_PAGE($id: ID!, $themeTemplateUri: ID!, $language: WPGraphQL_LanguageCodeFilterEnum) {
  wpgraphql {
    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      breadcrumbAcf {
        breadcrumbName
        image {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH, quality: 100)
            }
          }
        }
      }
      biographyCpt {
        featuredImage {
          altText
          sourceUrl
          imageFile {
            childImageSharp {
              gatsbyImageData(
                width: 450
                quality: 80
                placeholder: NONE
                layout: CONSTRAINED
              )
            }
          }
        }
        information
        biography
        workExperienceGroup {
          title
          experience {
            title
            percentage
          }
          skills {
            title
            description
            icon {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 38, quality: 100, placeholder: NONE, layout: FIXED)
                }
              }
            }
          }
        }
        doctorsAchievements
        achievementsGallery {
          image {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 230, quality: 100, placeholder: NONE, layout: FIXED)
              }
            }
          }
        }
      }
    }
    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    posts(
      first: 2
      where: {language: $language, orderby: {field: DATE, order: ASC}}
    ) {
      ...RecentPostsFragment
    }
  }
}
`
